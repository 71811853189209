// import { XMarkIcon } from '@heroicons/react/20/solid'

export default function Banner() {
  return (
    <div className="flex mt-5 items-center justify-center gap-x-6 bg-gradient-to-tr from-cyan-300 to-blue-400 px-6 py-4 sm:px-3.5 sm:before:flex-1">
      <p className="text-xl leading-6 text-white">
        <a href="https://wa.me/98615857 " >
          <strong className="font-semibold">10+ Years Experience </strong>
          -
          Reliable Service&nbsp;<span aria-hidden="true">&rarr;whatsapp: 98615857 </span>
        </a>
      </p>
      <div className="flex flex-1 justify-end">
        <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
          <span className="sr-only">Dismiss</span>
          {/* <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" /> */}
        </button>
      </div>
    </div>
  )
}