import NoPage from './nopage.js';
const products = [
  {
    name: 'Clear Retainer',
    brief: 'make in HK',
    features: [
      { name: 'Material', description: '1 mm or 1.5mm hard ', },
      { name: 'Price', description: '3 sets for 1899hkd(Upper and Lower)', },
    ],
    images: [
      { src: require('../images/products/spec1.jpg') },
      { src: require('../images/products/spec2.jpg') },
      { src: require('../images/products/spec3.jpg') },
      { src: require('../images/products/spec4.jpg') }
    ]
  },
  
]

export default function Product(props) {
  const id = parseInt(props.productID);
  if (isNaN(id)===false && id <= products.length) {
    const product = products[id - 1];
    const features = product.features;
    return (
      <div className="bg-white">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-32 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 ">{product.name}</h2>
            <p className="mt-4 text-gray-500 text-2xl">
              {product.brief}
            </p>

            <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
              {features.map((feature) => (
                <div key={feature.name} className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900 text-3xl">{feature.name}</dt>
                  <dd className="mt-2 text-gray-500  text-2xl">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
            {
              product.images.map((image) => (
                <img
                  src={image.src}
                  alt={product.name}
                  className="bg-gray-100 aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
              ))
            }

          </div>
        </div>
      </div>
    )
  } else {
    return <NoPage/>
  } 


}
