import React from 'react';

const Iframe = ({ source }) => {

    if (!source) {
        return <div>Loading...</div>;
    }

    const src = source;     
    return (
        <div className="col-md-12 lg:py-40">
            <div className="flex justify-center px-2">
            <iframe title="Eco-dental Location" className='w-[90%] h-96 max-h-full' src={src} ></iframe>
            </div>
        </div>
    );
};
export default Iframe;