export default function NoPage() {
    return (
        <div id="message" className="text-center mt-10">
        <h2 className="text-5xl mt-10 font-bold">404</h2>
        <h1 className="text-5xl mt-10 font-bold">Page Not Found</h1>
        <p className="text-3xl mt-20">The specified file was not found on this website. </p>
        <p className="text-3xl">Please check the URL for mistakes and try again.</p>
        <p className="text-3xl">Sorry For Any Inconvenient Caused.</p>
        <p className="text-3xl mt-20 mb-20"><a href="/">Back to Home Page.</a></p>
      </div>
    )
  }