const groups=[
  {
    imgSrc1: require("../images/products/14.jpg"),
    href1: "/product/1",
    imgSrc2: require("../images/products/2.jpg"),
    href2: "/product/2",
  },
  {
    imgSrc1: require("../images/products/3.jpg"),
    href1: "/product/1",
    imgSrc2: require("../images/products/4.jpg"),
    href2: "/product/2",
    imgSrc3: require("../images/products/5.jpg"),
    href3: "/product/3",
  },
  {
    imgSrc1: require("../images/products/6.jpg"),
    href1: "/product/1",
    imgSrc2: require("../images/products/7.jpg"),
    href2: "/product/2",
  },
  {
    imgSrc1: require("../images/products/8.jpg"),
    href1: "/product/1",
    imgSrc2: require("../images/products/9.jpg"),
    href2: "/product/2",
    imgSrc3: require("../images/products/16.jpg"),
    href3: "/product/3",
  },
  {
    imgSrc1: require("../images/products/11.jpg"),
    href1: "/product/1",
    imgSrc2: require("../images/products/12.jpg"),
    href2: "/product/2",
  },
  {
    imgSrc1: require("../images/products/13.jpg"),
    href1: "/product/1",
    imgSrc2: require("../images/products/1.jpg"),
    href2: "/product/2",
    imgSrc3: require("../images/products/15.jpg"),
    href3: "/product/3",
  },
  {
    imgSrc1: require("../images/products/10.jpg"),
    href1: "/product/1",
    imgSrc2: require("../images/products/17.jpg"),
    href2: "/product/2",
  },
  {
    imgSrc1: require("../images/products/18.jpg"),
    href1: "/product/1",
    imgSrc2: require("../images/products/19.jpg"),
    href2: "/product/2",
    imgSrc3: require("../images/products/20.jpg"),
    href3: "/product/3",
  },
  {
    imgSrc1: require("../images/products/21.jpg"),
    href1: "/product/1",
    imgSrc2: require("../images/products/22.jpg"),
    href2: "/product/2",
  },
  {
    imgSrc1: require("../images/products/23.jpg"),
    href1: "/product/1",
    imgSrc2: require("../images/products/spec1.jpg"),
    href2: "/product/2",
    imgSrc3: require("../images/products/spec2.jpg"),
    href3: "/product/3",
  },
  {
    imgSrc1: require("../images/products/spec3.jpg"),
    href1: "/product/1",
    imgSrc2: require("../images/products/spec4.jpg"),
    href2: "/product/2",
  },
  {
    imgSrc1: require("../images/products/1.jpg"),
    href1: "/product/1",
    imgSrc2: require("../images/products/2.jpg"),
    href2: "/product/2",
    imgSrc3: require("../images/products/3.jpg"),
    href3: "/product/3",
  },
 
]

export default function Products() {
  return (
    <div className="mx-auto mt-16 w-full sm:mt-20 px-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 gap-4 mb-20 items-center mx-auto justify-center" >
          {groups.map((group) => {
            if (group.imgSrc3){
              return <div className="justify-center grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
              <div className="mx-auto h-64 w-54 overflow-hidden rounded-lg animate-float-2s ">
                <a href={group.href1}>
                <img
                  src={group.imgSrc1}
                  alt=""
                  className="rounded-[30%] h-full w-full object-cover object-center "
                />
                </a>
              </div>
              <div className="mx-auto h-64 w-54 overflow-hidden rounded-lg animate-float">
              <a href={group.href2}>
                <img
                  src={group.imgSrc2}
                  alt=""
                  className="rounded-[30%] h-full w-full object-cover object-center "
                />
                </a>
              </div>
              <div className="mx-auto h-64 w-54 overflow-hidden rounded-lg animate-float-1s">
              <a href={group.href3}>
                <img
                  src={group.imgSrc3}
                  alt=""
                  className="rounded-[30%] h-full w-full object-cover object-center "
                />
                </a>
              </div>
              </div>
            }else{
              return <div className="justify-center grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                <div className="mx-auto h-64 w-54 overflow-hidden rounded-lg animate-float-1s">
                  <a href={group.href1}>
                  <img
                    src={group.imgSrc1}
                    alt=""
                    className="rounded-[30%] h-full w-full object-cover object-center "
                  />
                  </a>
                </div>
                <div className="mx-auto h-64 w-54 overflow-hidden rounded-lg animate-float-2s">
                <a href={group.href1}>
                  <img
                    src={group.imgSrc2}
                    alt=""
                    className="rounded-[30%] h-full w-full object-cover object-center "
                  />
                  </a>
                </div>
              </div>
            }
            
          })}
            
      </div>
    </div>
  );
}

