function Hero() {
    return (
      <section className="bg-gradient-to-tr from-cyan-300 to-blue-400 text-white p-10 text-center">
        <h1 className="text-6xl lg:text-9xl md:text-9xl mt-40"><strong>Easy Retainer HK</strong></h1>
        <p className="text-4xl mt-40 mb-40">High quality dental products, eco-friendly and affordable.</p>
      </section>
    );
  }
  
  export default Hero;
  