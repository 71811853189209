import React from "react";
import Header from './components/header.js';
import Home from './pages/home.js';
import ProductPage from './pages/productPage.js';
import Contact from './components/contact.js';
import Banner from "./components/banner.js"
// import Footer from "./components/footer.js";
import Product from "./pages/product.js";
import NoPage from "./pages/nopage.js";
function App() {

  let page;

  let path = window.location.pathname;
  console.log(path,'path');
  if(path.startsWith('/product/')){
    page = (<div><Banner/><Product productID={1} /></div>);
  }else{
    switch (window.location.pathname){

      case "/products":
        page = <ProductPage/>;
        break;
      case "/contact":
        page = (<div><Banner/><Contact/> </div>);
        break;
      case "/":
        page = <Home/>;
        break;
      default:
        page = <NoPage/>;
        break;
    }
  }

 

  return (
    <div className="App font-custom">

      <Header/>
      
      {page}

    </div>
  );
}

export default App;
