import Banner from '../components/banner.js';
import Products from '../components/products.js';

export default function ProductPage() {
  return (
    <div>
        <Banner/>
        <Products/>
    </div>
  )
}